import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link } from 'gatsby';
import Config from 'config/Config';
import logo from 'images/logo-256.png';
import GenericUtils from 'utils/GenericUtils';

type NavBarLink = {
  name: string;
  url: string;
  isNew?: boolean;
  className?: string;
};

type Props = {
  links: NavBarLink[];
  className?: string;
};

type State = {
  hasScrolled: boolean;
};

export default class NavBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasScrolled: false,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  private handleScroll = () => {
    this.setState({
      hasScrolled: window.scrollY > 20
    });
  };

  render() {
    const { hasScrolled } = this.state;
    const { links, className } = this.props;

    return (
      <nav className={GenericUtils.classNames(hasScrolled ? `nav-bar nav-bar--is-scrolled` : 'nav-bar', className)}>
        <div className="nav-bar__container">

          <div className="nav-bar__brand">
            <Link to="/" className="nav-bar__brand__link">
              <img src={logo} alt="Big Tits Webcam Girls' Logo" className="nav-bar__brand__logo" />
              <span className="nav-bar__brand__text">{Config.SiteTitle}</span>
            </Link>
          </div>

          <div className="nav-bar__links-container">
            {links.map(l => (
              <Link key={l.name} to={l.url} activeClassName="active" className={GenericUtils.classNames('nav-bar__links-container__link', l.className, l.isNew ? 'nav-bar__links-container__link--new' : undefined)}>
                {l.name}
                {l.isNew && (
                  <FontAwesomeIcon icon={['fas', 'gift']} className="nav-bar__links-container__link-icon" />
                )}
              </Link>
            ))}
          </div>

        </div>
      </nav>
    );
  }

}
