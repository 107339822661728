import React, { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import ModelCard from 'components/ModelCard';
import Model from 'models/Model';
import Tag from 'models/Tag';
import GenericUtils from 'utils/GenericUtils';

type Props = {
  models: Model[];

  tags?: Tag[];
  sortBy?: 'online';
  className?: string;
};

export default function ModelList({ models, tags, sortBy, className }: Props) {

  function getModels(): Model[] {
    let filteredModels = cloneDeep(models);

    filteredModels = filteredModels.filter(m => m.isOnline);

    // Filter by tags
    if (tags && tags.length > 0) {
      filteredModels = filteredModels.filter(m => {
        const modelTags = m.tags || [];
        return tags.every(sT => modelTags.findIndex(t => t === sT.name) !== -1);
      });
    }

    if (!sortBy) {
      return filteredModels;
    }

    // Sort
    switch (sortBy) {
      case 'online':
        return filteredModels.sort((a, b) => {
          if (a.isOnline && !b.isOnline) {
            return -1;
          } else if (!a.isOnline && b.isOnline) {
            return 1;
          } else {
            return 0;
          }
        });
      default:
        return filteredModels;
    }
  }

  const [imageUpdateParam, setImageUpdateParam] = useState(new Date().getTime());

  useEffect(() => {
    function updateImagesTimer() {
      setImageUpdateParam(new Date().getTime());
    }

    function clearUpdateImageTimer() {
      clearInterval(timer);
    }

    const timer = setInterval(updateImagesTimer, 3000);

    return clearUpdateImageTimer;
  }, []);

  const filteredModels = getModels();

  if (filteredModels.length === 0 && (tags || []).length > 0) {
    return (
      <div className={GenericUtils.classNames('model-list__empty-view', className)}>
        <p className="model-list__empty-view__text">😞 Unfortunately no model matches these tags: <i>{tags!.map(t => t.name).join(', ')}</i></p>
      </div>
    );
  }

  return (
    <div className={GenericUtils.classNames('model-list', className)}>
      {filteredModels.map(m => <ModelCard key={m.username} model={m} imageParam={imageUpdateParam} />)}
    </div>
  );
}
