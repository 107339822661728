import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faVideo, faExpand, faTimesCircle, faGift, faPhotoVideo, faImages, faSearch } from '@fortawesome/free-solid-svg-icons';

export default class IconUtils {

  static initLibrary() {
    library.add(fab, faVideo, faExpand, faTimesCircle, faGift, faPhotoVideo, faImages, faSearch);
  }

}
