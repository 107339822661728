Object.defineProperty(exports, "__esModule", { value: true });
const Config = {
    SiteTitle: 'Big Tits Webcam Girls',
    SiteShortTitle: 'Big Tits Webcam Girls',
    SiteAltTitle: 'Big Tits Webcam Girls',
    SiteDescription: 'A curated list of big tits camgirls',
    SiteLogo: '/logo/logo-1024.jpg',
    SiteUrl: 'https://bigtitswebcamgirls.com',
    SiteLanguage: 'en-gb',
    TitleDivider: '|',
    PathPrefix: '/',
    BackgroundColor: '#000',
    ThemeColor: '#fd9927',
    DateFromFormat: 'DD-MM-YYYY',
    DateFormat: 'DD MMM, YYYY',
    Author: 'BigTitsWebcamGirls',
    Publisher: 'BigTitsWebcamGirls',
    NavBarLinks: [
        {
            name: 'Categories',
            url: '/categories',
        },
        {
            name: 'Blog',
            url: '/blog',
        },
    ],
    FooterLinks: [
        {
            name: 'Home',
            url: '/',
        },
        {
            name: 'Categories',
            url: '/categories',
        },
        {
            name: 'Blog',
            url: '/blog',
        },
        {
            name: 'About Us',
            url: '/about',
        },
        {
            name: 'Contacts',
            url: '/contacts',
        },
    ],
    ChaturbateCampaign: 'MNyc3',
    TwitterUser: 'BTWG4',
    RedditUser: 'bigtitswebcamgirls',
    InstagramUser: 'b_t_w_g',
    TelegramUser: 'b_t_w_g',
    GoogleTagManagerId: 'GTM-P87HT6H',
    PinterestUser: 'big_tits_webcam_girls',
    PinterestClaim: 'ef40e7719d23c2bfaf17c55162152b18',
};
exports.default = Config;
