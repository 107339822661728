import React from 'react';
import ModelImage from 'components/ModelImage';
import Model from 'models/Model';
import RouteUtils from 'utils/RouteUtils';

type Props = {
  model: Model;

  imageParam?: number;
};

export default function ModelCard({ model, imageParam }: Props) {
  const roomUrl = RouteUtils.generatePathToModelRoom(model);

  return (
    <div className="model-card">
        <a
          href={roomUrl}
          target="_blank"
          title={`Chat room of ${model.username}`}
          className="model-card__image-container"
        >
          <ModelImage
            model={model}
            imageParam={imageParam}
            className="model-card__image"
          />
          <div className="model-card__info-container">
            <span className="model-card__model-name">
              {model.username}
            </span>
          </div>

          {model.isOnline && (
            <span className="model-card__online-badge">
              Live now!
            </span>
          )}
        </a>
    </div>
  );
}
