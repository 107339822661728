import * as React from 'react';
import Helmet from 'react-helmet';
import NavBar from 'components/NavBar';
import Footer from 'components/Footer';
import Config from 'config/Config';
import IconUtils from 'utils/IconUtils';
import GenericUtils from 'utils/GenericUtils';
import logo from 'images/logo-256.png';
import 'styles/main.scss';

IconUtils.initLibrary();

type Props = {
  title?: string;
  description?: string;
  navBarClassName?: string;
  mainClassName?: string;
  footerClassName?: string;
}

export default class Master extends React.Component<Props> {
  render() {
    const { title, description, children } = this.props;

    return (
      <>
        <Helmet title={title || Config.SiteTitle} htmlAttributes={{ lang: Config.SiteLanguage }}>
          <meta name="description" content={description || Config.SiteDescription}/>
          <link rel="shortcut icon" type="image/png" href={logo}/>
          <meta name="p:domain_verify" content={Config.PinterestClaim}/>
        </Helmet>

        <NavBar 
          links={Config.NavBarLinks} 
          className={this.props.navBarClassName}
        />
        
        <main className={GenericUtils.classNames('master-layout__main', this.props.mainClassName)}>
          {children}
        </main>
        
        <Footer 
          links={Config.FooterLinks}
          className={this.props.footerClassName}
        />
      </>
    )
  }
}
