import Model from 'models/Model';
import Config from 'config/Config';
import ModelPhotoSet from '~/models/ModelPhotoSet';

// const BASE_URL = false ? `https://chaturbate.com` : `https://cb.bigtitswebcamgirls.com`;
const BaseUrl = {
  cb: `https://chaturbate.com`,
  btwg: `https://cb.bigtitswebcamgirls.com`,
};

export default class ModelUtils {
  static getRoomImageUrl(m: Model): string {
    return `https://roomimg.stream.highwebmedia.com/ri/${m.username}.jpg`;
  }

  static getFanClubUrl(m: Model): string {
    return `${BaseUrl.cb}${m.fanClubUrl}?campaign=${Config.ChaturbateCampaign}`;
  }

  static getRoomUrl(m: Model, track: string = 'default'): string {
    return `${BaseUrl.btwg}/in/?track=${track}&tour=LQps&campaign=${Config.ChaturbateCampaign}&room=${m.username}`;
  }

  static getRoomFullVideoUrl(m: Model, track: string = 'default', isMobile: boolean): string {
    return `${BaseUrl.btwg}/in/?track=${track}&tour=dU9X&campaign=${Config.ChaturbateCampaign}&b=${m.username}&signup_notice=1&disable_sound=1&embed_video_only=1&mobileRedirect=${isMobile ? 'always' : 'never'}`;

    // return `https://cb.bigtitswebcamgirls.com/in/?tour=9oGW&campaign=${Config.ChaturbateCampaign}&track=${track}&room=${m.username}&bgcolor=transparent&disable_sound=0&embed_video_only=1&target=_blank`;
  }

  static getRoomFrameUrl(m: Model, track: string = 'default', isMobile: boolean): string {
    const desktopLink = `${BaseUrl.btwg}/in/?track=${track}&tour=9oGW&campaign=${Config.ChaturbateCampaign}&room=${m.username}&bgcolor=white&disable_sound=1&embed_video_only=0&target=_parent&mobileRedirect=never`;
    const mobileLink = `${BaseUrl.btwg}/affiliates/in/?tour=9oGW&campaign=${Config.ChaturbateCampaign}&track=${track}&room=${m.username}&disable_sound=1&embed_video_only=1&target=_parent&mobileRedirect=always`;

    return isMobile ? mobileLink : desktopLink;
  }

  static getSignupUrl(m: Model): string {
    return `${BaseUrl.cb}/accounts/register/?room=${m.username}&redirect_to_room=${m.username}&tour=9oGW&campaign=${Config.ChaturbateCampaign}`;
  }

  static getJoinPageUrl(m: Model, track: string = 'default', isMobile: boolean): string {
    // const desktopLink = `${BaseUrl.cb}/${m.username}/?track=${track}&tour=9oGW&campaign=${Config.ChaturbateCampaign}&disable_sound=1&target=_parent&mobileRedirect=never`;
    // const mobileLink = `${BaseUrl.cb}/${m.username}/?tour=9oGW&disable_sound=1&join_overlay=1&campaign=${Config.ChaturbateCampaign}&room=${m.username}`;

    return `${BaseUrl.cb}/${m.username}/?tour=LQps&disable_sound=0&join_overlay=1&campaign=${Config.ChaturbateCampaign}`;
  }

  static getModelImageUrl(m: Model, size?: number): string {
    if (size !== undefined) {
      // return `https://btwg.imfast.io/cdn-cgi/image/width=${size}/cam-girls/${m.username}/default.jpg`;
      return `/img/cam-girls/${m.username}/default.${size}.jpg`;
    }

    // return `https://btwg.imfast.io/cam-girls/${m.username}/default.jpg`;
    return `/img/cam-girls/${m.username}/default.jpg`;
  }

  static getModelPlaceholderUrl(): string {
    return `/img/loading.gif`;
  }

  static getModelBabepediaUrl(m: Model): string {
    return `https://www.babepedia.com/babe/${m.babepediaUsername}`;
  }

  static getModelTwitterUrl(m: Model): string {
    return `https://twitter.com/${m.twitterUsername}`;
  }

  static getModelInstagramUrl(m: Model): string {
    return `https://www.instagram.com/${m.instagramUsername}`;
  }

  static getModelPhotoSetUrl(m: Model, pS: ModelPhotoSet): string {
    return ModelUtils.getSignupUrl(m);
    // return `${BaseUrl.cb}/photo_videos/photoset/detail/${m.username}/${pS.id}/?campaign=${Config.ChaturbateCampaign}`;
  }
}

// Link Cam69

// Desktop:
// https://chaturbate.com/in/?track=cam69_embed&tour=9oGW&campaign=L6Bd4&room=realtoxxxmaria&bgcolor=white&disable_sound=1&embed_video_only=0&target=_parent&mobileRedirect=never

// Mobile:
// https://auth.cam69.com/affiliates/in/?tour=9oGW&campaign=L6Bd4&track=cam69&room=realtoxxxmaria&bgcolor=white&disable_sound=1&embed_video_only=1&target=_parent

// Link Type Here:
// https://chaturbate.com/in/?track=cam69_button&tour=dT8X&campaign=L6Bd4&room=realtoxxxmaria
// https://it.chaturbate.com/wetdream111/?force=1&join_overlay=1&campaign=L6Bd4&disable_sound=0&tour=dT8X&room=wetdream111

// Image for cams:
// https://cam69.com/media/chaturbate/w/e/wetdream111/large0.jpg

// My getRoomFrameUrl links:
// Mobile: `https://cb.bigtitswebcamgirls.com/in/?track=${track}&tour=9oGW&campaign=${Config.ChaturbateCampaign}&room=${m.username}&bgcolor=black&disable_sound=1&embed_video_only=1&target=_parent&mobileRedirect=always` :
// Desktop: `https://cb.bigtitswebcamgirls.com/in/?track=${track}&tour=dU9X&campaign=${Config.ChaturbateCampaign}&b=${m.username}&signup_notice=1&disable_sound=1&mobileRedirect=never`;
