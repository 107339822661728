import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Model from 'models/Model';
import ModelUtils from 'models/Model.utils';

type Props = {
  model: Model;

  imageParam?: number;
  className?: string;
};

export default function ModelImage({ model, imageParam, className }: Props) {
  let src = ModelUtils.getRoomImageUrl(model);
  if (imageParam) {
    src += `?t=${imageParam}`;
  }

  return (
    <LazyLoadImage
      src={src}
      alt={`Webcam girl ${model.username}`}
      placeholderSrc={ModelUtils.getModelPlaceholderUrl()}
      effect="blur"
      className={className}
      wrapperClassName={className}
    />
  );
}
