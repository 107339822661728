import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Config from 'config/Config';
import GenericUtils from 'utils/GenericUtils';

type FooterLink = {
  name: string;
  url: string;
};

type Props = {
  links: FooterLink[];
  className?: string;
}

export default class Footer extends React.Component<Props> {
  render() {
    return (
      <footer className={GenericUtils.classNames('footer', this.props.className)}>
        <div className="footer__info-container container">
          <p className="footer__info-container__text">
            Big Tits Webcam Girls collects beautiful camgirls with big boobs
          </p>

          <div className="footer__links-container">
            {this.props.links.map(l => <a key={l.name} href={l.url} className="footer__info-container__link">{l.name}</a>)}
          </div>

          <div className="footer__links-container">
            <a href={`https://twitter.com/${Config.TwitterUser}`} target="_blank" className="footer__link">
              <FontAwesomeIcon icon={['fab', 'twitter']} /> <span className="footer__link-text">Twitter</span>
            </a>
            <a href={`https://pinterest.com/${Config.PinterestUser}`} target="_blank" className="footer__link">
              <FontAwesomeIcon icon={['fab', 'pinterest']} /> <span className="footer__link-text">Pinterest</span>
            </a>
            <a href={`https://t.me/${Config.TelegramUser}`} target="_blank" className="footer__link">
              <FontAwesomeIcon icon={['fab', 'telegram']} /> <span className="footer__link-text">Telegram</span>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}
