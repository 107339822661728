import Config from 'config/Config';
import Model from 'models/Model';
import GenericUtils from 'utils/GenericUtils';

export default class PageUtils {

  static generateTitle(title: string): string {
    return `${GenericUtils.capitalize(title)} ${Config.TitleDivider} ${Config.SiteTitle}`;
  }

  static generateModelRoomDescription(model: Model): string {
    return `Watch what's going on in the ${model.username}'s room, a big tits camgirl selected by ${Config.SiteTitle}`;
  }
}
